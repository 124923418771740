<script setup lang="ts">
// Components
import incidentListCard from '../components/cards/incidentListCard.vue';

const incidents = incidentStore();

// Computed
const getIncidentOpened = computed(() => incidentStore().getNumberOfIncidentsOpenThanMoreThat5Days);
const getOverCost = computed(() => incidentStore().getOverCost);

// Lifecycle
onMounted(() => {
  incidentStore().loadIncidents();
});
</script>
<template>
  <ui-header :title="$t('global.incidents')" />

  <div class="flex justify-start flex-col gap-4 p-4 overflow-y-auto">
    <!-- Stats -->
    <div class="grid gap-4 grid-cols-2">
      <ui-stat-card
        :loading="incidents.loading"
        :title="$t('incident.incident_open_for_more_than_5_days')"
        type="info"
        icon="Hash"
        :data="getIncidentOpened"
      />
      <ui-stat-card :loading="incidents.loading" :title="$t('incident.overcost')" type="euro" :data="getOverCost" />
    </div>

    <!-- Card -->
    <incident-list-card />
  </div>
</template>
