import { useI18n } from 'vue-i18n';
import type { Header } from '~/components/ui/data/DataTable.vue';
import type { TableAction } from '~/components/ui/data/DataTableActions.vue';

export const useIncidentCard = () => {
  // Composables
  const { t } = useI18n();
  const selectedTab = ref(0);

  const tabs = ref([
    {
      id: 0,
      label: t('incidents.statuses.incident_progress'),
    },
    {
      id: 1,
      label: t('incidents.statuses.incident_completed'),
    },
    {
      id: 2,
      label: t('incidents.statuses.incident_closed'),
    },
  ]);

  const tableHeader = computed((): Header[] => {
    return [
      {
        key: 'name',
        label: t('incidents.incident_name'),
      },
      {
        key: 'cost',
        label: t('global.cost'),
      },
      {
        key: 'area',
        label: t('incident.area'),
      },
      {
        key: 'start_date',
        label: t('global.start_date'),
      },
      {
        key: 'end_date',
        label: t('global.end_date'),
      },
      {
        key: 'reporter',
        label: t('incidents.reported_by'),
      },
      {
        key: 'action',
        label: '',
        align: 'center',
      },
    ];
  });

  const tableActions = computed((): TableAction[] => {
    return [
      {
        key: 'view',
        label: t('incident.view_incident'),
        icon: 'Eye',
        iconStokeWidth: 2,
      },
      {
        key: 'edit',
        label: t('incident.edit_incident'),
        icon: 'Pen',
        iconStokeWidth: 2,
      },
    ];
  });

  return { tableHeader, selectedTab, tabs, tableActions };
};
