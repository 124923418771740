<script setup lang="ts">
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import incidentManageModal from '../modals/incidentManageModal.vue';
import incidentResolveModal from '../modals/incidentResolveModal.vue';
import type { Incident } from '~/types/incident';
import { useI18n } from 'vue-i18n';

// Store
const incidents = incidentStore();

// Composables
const router = useRouter();
const { locale } = useI18n();
const { tabs, selectedTab, tableHeader, tableActions } = useIncidentCard();

// Data
const createModal = ref(false);
const selectedIncident = ref<Incident | null>(null);
const closeIncidentModal = ref(false);

// Methods
const clickItem = (event: string, item: Incident) => {
  if (event === 'view') {
    selectedIncident.value = null;
    router.push(`/incident/${item.id}`);
  } else if (event === 'edit') {
    selectedIncident.value = item;
    createModal.value = true;
  }
};

const openCreateModal = () => {
  selectedIncident.value = null;
  createModal.value = true;
};

const closeIncident = (incident: Incident) => {
  selectedIncident.value = incident;
  closeIncidentModal.value = true;
};

// Computed
const getAlertByType = computed(() => {
  if (selectedTab.value === 0) return incidents.getIncidentTypeAsInProgress;
  else if (selectedTab.value === 1) return incidents.getIncidentTypeAsDone;
  else if (selectedTab.value === 2) return incidents.getIncidentTypeAsClose;
  return [];
});
</script>
<template>
  <ui-card :title="$t('indicents.list_of_incidents')">
    <template #header>
      <ui-button left-icon="Plus" @click="openCreateModal">
        {{ $t('incidents.create_an_incident') }}
      </ui-button>
    </template>

    <ui-tab v-model="selectedTab" :list-tabs="tabs" class="mb-4" />

    <ui-data-table :loading="incidents.loading" :headers="tableHeader" :items="getAlertByType" @click-row="clickItem('view', $event)">
      <template #item-cost="{ item }">
        <ui-badge v-if="item.cost" color="red"> {{ item.cost }} € </ui-badge>
      </template>
      <template #item-area="{ item }">
        <ui-badge color="blue">{{ item.area.name }}</ui-badge>
      </template>
      <template #item-start_date="{ item }">
        {{
          dayjs(item.start)
            .locale(locale)
            .format(`dddDD MMM YYYY ${$t('global.at')} HH:mm`)
        }}
      </template>
      <template #item-end_date="{ item }">
        {{
          dayjs(item.end)
            .locale(locale)
            .format(`dddDD MMM YYYY ${$t('global.at')} HH:mm`)
        }}
      </template>
      <template #item-reporter="{ item }">
        <div v-if="item.alert_id" class="flex items-center">
          <ui-avatar photo-url="logo.jpg" />
          <p class="text-[#52525B] text-sm ml-2">NRJx</p>
        </div>
        <div v-else class="flex items-center">
          <ui-avatar v-if="item.source_user" :firstname="item.source_user.first_name" :lastname="item.source_user.last_name" />
          <p v-if="item.source_user" class="text-[#52525B] text-sm ml-2">
            {{ item.source_user ? `${item.source_user.first_name} ${item.source_user.last_name}` : ' --' }}
          </p>
        </div>
      </template>
      <template #item-action="{ item }">
        <ui-button v-if="selectedTab === 0" size="xs" color="secondary" @click="closeIncident(item)">
          {{ $t('incident.close') }}
        </ui-button>
        <ui-data-table-actions :items="tableActions" @click-item="clickItem($event, item)" />
      </template>
    </ui-data-table>
  </ui-card>

  <!-- Create modal -->
  <incident-manage-modal v-model="createModal" :incident="selectedIncident" />
  <incident-resolve-modal v-if="selectedIncident" v-model="closeIncidentModal" :incident="selectedIncident" />
</template>
